<template>
  <div>
    <b-row v-if="refundData" class="row-eq-height">
      <b-col class="mb-1" cols="6">
        <b-card :title="$t('Order_Show.Refund')" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">{{ $t("Order_Show.ID") }}</th>
                <td class="text-right">{{ refundData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Code") }}</th>
                <td class="text-right">{{ refundData.code }}</td>
              </tr>
              <tr v-if="refundData.is_service_cost">
                <th class="text-left">Service Cost</th>
                <td class="text-right">{{ refundData.service_cost }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Total_Refunded") }}</th>
                <td class="text-right">{{ refundData.total_refunded }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">{{ $t("Order_Show.Status") }}</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ refundData.status }}
                  </b-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-row v-for="(order, index) in refundData.orders" :key="index">
          <b-col md="12">
            <app-collapse id="faq-payment-qna" type="margin" class="mt-0 mb-0">
              <app-collapse-item
                :key="'variantIndex' + index"
                :title="'كود الطلب   ' + order.code"
                              style="color: #046D6D;"
              >
                <b-row>
                  <b-col class="mb-1" md="6">
                    <b-card :title="$t('Order_Show.Order')" class="h-100">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">{{ $t("Order_Show.ID") }}</th>
                            <td class="text-right">{{ order.id }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Code") }}
                            </th>
                            <td class="text-right">{{ order.code }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Status") }}
                            </th>
                            <td class="text-right">
                              <b-badge variant="primary">
                                {{ order.orderstatus.name }}
                              </b-badge>
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Type") }}
                            </th>
                            <td class="text-right">{{ order.type }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Note") }}
                            </th>
                            <td class="text-right">{{ order.note }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Date") }}
                            </th>
                            <td class="text-right">{{ order.created_at }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card>
                  </b-col>
                  <b-col md="6" class="mb-1">
                    <b-card :title="$t('Order_Show.Order_Total')" class="h-100">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Tax") }}
                            </th>
                            <td class="text-right">{{ order.tax }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Fees") }}
                            </th>
                            <td class="text-right">{{ order.fees }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Shipping_Price") }}
                            </th>
                            <td class="text-right">
                              {{ order.shipping_price }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Discount") }}
                            </th>
                            <td class="text-right">
                              {{
                                order.coupon != null
                                  ? order.coupon.percentage
                                  : "-"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Total") }}
                            </th>
                            <th class="text-right">{{ order.total }}</th>
                          </tr>
                          <tr>
                            <th class="text-left">
                              {{ $t("Order_Show.Total_Paid") }}
                            </th>
                            <th class="text-right">{{ order.total_paid }}</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-card>
                  </b-col>
                </b-row>
                <!-- User Info: Left col -->

                <b-col cols="12">
                  <b-card :title="$t('Order_Show.Products')">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">{{ $t("Order_Show.ID") }}</th>
                          <th scope="col">{{ $t("Order_Show.Name") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in order.variants"
                          :key="index"
                        >
                          <th scope="row">{{ item.id }}</th>
                          <td>{{ item.name }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card>
                </b-col>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { heightTransition } from "@core/mixins/ui/transition";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { required } from "@validations";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [heightTransition],
  data() {
    return {
      area: "",
      required,
      delUser: true,
      imgIndex: null,
      profileArr: [],
      firstnameV: "",
      firstnameU: "",
      lastnameV: "",
      loggerNote: {
        note: "",
      },
      lastnameU: "",
      addressV: "",
      addressU: "",
      phoneV: "",
      phoneU: "",
      emailV: "",
      emailU: "",
      discount: "",
      storename: "",
      profilePicV: "",
      statusV: "",
      idV: "",
      idU: "",
      modalShow: false,
      orderStatus: "",
      showDismissibleAlert: false,
      id: 0,
      errors_back: [],
      required,

      refundData: {},
      statusSelect: null,
      refundSataus: [
        {
          text: "Cancel",
          value: "cancel",
        },
        {
          text: "Accept",
          value: "accept",
        },
      ],
    };
  },
  created() {
    // request status data
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("refunds/" + this.id + "/show")
        .then((result) => {
          this.refundData = result.data.data;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },

    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
